import Vue from "vue";
import axios from "axios";
import qs from "qs";
import merge from "lodash/merge";

// baseUrl
axios.defaults.baseURL = process.env.VUE_APP_API_HOST;

// const httpw = axios.create({
//     timeout: 1000 * 30,
//     // withCredentials: false,
//     headers: {
//         // 'Content-Type': 'application/json; charset=utf-8'
//         'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
//     }
// });

/**
 * 设置拦截器，interceptors 响应处理
 * 所有使用 axios 发送的请求，在请求发送出去之前，都会进入到方法中
 * 在这里，为所有请求添加上 token 。
 */
axios.interceptors.request.use(
    config => {
        // 获取token
        const token = sessionStorage.getItem("access_token"); // getToken()
        if (token) {
            config.headers.common["Authorization"] = `Bearer ${token}`;
            // config.headers.common['z-token'] = `${token}`;
        }
        config.headers.common["Access-Control-Allow-Origin"] = "*";
        // 统一获取userId
        const userId = sessionStorage.getItem("user_id");
        console.log("before add userId", config, userId);
        // Content-Type: application/x-www-form-urlencoded
        // 涉及到更新的操作 暂时不需要userId
        // 管理员页面 除了查询表格数据，其他的都不需要userId
        if (
            config["url"].indexOf("update") !== -1 ||
            config["url"].indexOf("enable") !== -1 ||
            config["url"].indexOf("Enable") !== -1 ||
            config["url"].indexOf("userAdmin/resetPwd") !== -1 ||
            config["url"].indexOf("userAdmin/getById") !== -1 ||
            config["url"].indexOf("userAdmin/delete") !== -1 ||
            config["url"].indexOf("userAdmin/save") !== -1 ||
            config["url"].indexOf("admin/pc/login") !== -1
        ) {
            console.log("更新操作 不需要userId", config, config.headers);
            return config;
        }
        if (userId) {
            if (config.method === "get") {
                config.params = {
                    userId: userId,
                    ...config.params
                };
                console.log("get userId", config.params);
                return config;
            }
            if (
                config.headers.post["Content-Type"] ===
                    "application/x-www-form-urlencoded" &&
                config.headers["Content-Type"] !==
                    "application/json; charset=utf-8"
            ) {
                config.data = `${config.data}&userId=${userId}`;
                console.log("application/x-www-form-urlencoded", config.data);
                return config;
            }
            // 判断请求的类型：如果是post请求就把默认参数拼到data里面；如果是get请求就拼到params里面
            if (config.method === "post") {
                try {
                    const postData = JSON.parse(config.data);
                    config.data = JSON.stringify({
                        userId: userId,
                        ...postData
                    });
                    console.log("post", config.dat);
                } catch (error) {
                    console.log(
                        "JSON.parse(config.data)错误 in axios.config.js"
                    );
                }
                console.log("post userId", config);
            }
        }
        return config;
    },
    function(error) {
        // 对请求错误做些什么
        return error;
    }
);

/**
 * 设置拦截器，interceptors 响应处理
 * 所有使用 axios 的请求响应，都会优先回调到拦截器中
 * 在正确的返回情况下，数据会优先进入第一个回调方法
 * 在请求错误的情况下，错误会进入第二个回调方法
 */
axios.interceptors.response.use(
    response => {
        //  统一处理数据，使组件中的请求只获取到需要的业务数据，
        // 而不需要去关注返回的状态码等与业务无关的数据
        // console.log('响应拦截器');

        return response.data;
    },
    error => {
        // 处理错误的响应
        return Promise.reject(error);
    }
);

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
axios.adornParams = (params = {}, openDefultParams = true) => {
    let defaults = {
        t: new Date().getTime()
    };
    return openDefultParams ? merge(defaults, params) : params;
};

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
axios.gets = (url, params) => {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params
            })
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err.data);
            });
    });
};

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
axios.adornData = (data = {}, openDefultdata = true, contentType = "json") => {
    let defaults = {
        t: new Date().getTime()
    };
    let data1 = openDefultdata ? merge(defaults, data) : data;
    return contentType === "json" ? JSON.stringify(data1) : qs.stringify(data1);
};

axios.jsonContent = () => {
    let defaults = {
        headers: { "Content-Type": "application/json; charset=utf-8" }
    };
    return defaults;
};

axios.adornUrl = url => {
    return axios.defaults.baseURL + url;
};
//  绑定到 vue 原型中，组件中 ： this.$http -> axios 。

export default axios;
Vue.prototype.$http = axios;
