import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        meta: {
            title: '登录'
        },
        // component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        component: (resolve) => require(['../views/Login.vue'], resolve)
    },
    {
        path: '/main',
        name: 'Main',
        meta: {
            title: '首页'
        },
        // component: () => import(/* webpackChunkName: "main" */ '../views/Main.vue'),
        component: (resolve) => require(['../views/Main.vue'], resolve),
        children: [
            // 用户管理 --- 用户列表
            {
                path: '',
                redirect: 'users/list'

            },
            // 用户管理 --- 用户列表
            {
                path: 'users/list',
                name: 'usersList',
                meta: {
                    title: '用户列表'
                },
                component: (resolve) => require(['../views/usersList/usersList.vue'], resolve)
            },
            // 训练管理 --- 训练列表
            {
                path: 'train/list',
                name: 'trainList',
                meta: {
                    title: '训练列表'
                },
                component: (resolve) => require(['../views/trainList/trainList.vue'], resolve)
            },
            // 订单管理 --- 订单列表
            {
                path: 'order/list',
                name: 'orderList',
                meta: {
                    title: '订单列表'
                },
                component: (resolve) => require(['../views/orderList/orderList.vue'], resolve)
            },
        ]
    },
    {
        path: '/mainfixheader',
        name: 'MainFixHeader',
        meta: {
            title: '测试固定表头'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/demo/MainFixHeader.vue'),
    },
    {
        path: '/testapi',
        name: 'testapi',
        meta: {
            title: '测试api请求'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/demo/TestHttps.vue'),
    },
    {
        path: '/testg2',
        name: 'testapi',
        meta: {
            title: '测试api请求'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/demo/TestG2.vue'),
    },


];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title) document.title = '[脑健康康复训练系统] - ' + to.meta.title
    next()
})
