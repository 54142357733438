import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import './assets/css/common.css'
import '@js/axios.config.js'
import '@js/http.js'
import Print from './assets/utils/print.js';

Vue.use(Print);

Vue.use(ViewUI);
Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
    let isAuthenticated = sessionStorage.getItem('access_token') ? true : false
    if (to.name !== 'login' && !isAuthenticated) next({ name: 'login' })
    else next()
})
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
Vue.prototype.$Message.config({
    top: 200,
    duration: 2
});
